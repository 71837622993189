import React from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';

import CustomerChatBoard from './components/CustomerChatBoard';

const Root = (props) => {
  return (
    <CustomerChatBoard/>
  )
}

ReactDOM.render(<Root />,
  document.getElementById('root')
);
serviceWorker.unregister();
