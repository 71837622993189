import moment from 'moment';
import React from 'react';
import User from '../../../assets/images/user.jfif';
import Agent from '../../../assets/images/agent-avatar.svg';
import LogoMohe from '../../../assets/images/logoMohe.png';
import Chatbot from "../../../assets/images/chatbot.svg";
import { optionSplitText } from './chatBot';
import { getUrls, isPdfUrl, isStringEmpty, removeUrl,isYoutube } from '../../../utils/filters';
import PdfDownloader from './pdfDownloader';
import LinkDownloader from './linkDownloader';
import ReactPlayer from 'react-player'

export const MESSAGE_MARKER = {
  MARKABLE: 'markable',
  RECEIVED: 'received',
  DISPLAYED: 'displayed',
};

export const renderCheck = (marker = {}, notSent) => {
  let check = '';
  const type = marker.type;
  if (notSent) return <i className="fa fa-clock-o m-1"></i>;
  if (type === MESSAGE_MARKER.MARKABLE) {
    check = <i className="fa fa-check m-1"></i>;
  } else if (type === MESSAGE_MARKER.RECEIVED) {
    check = (
      <span className="marker-container" style={{}}>
        <i className="fa fa-check"></i>
        <i className="fa fa-check"></i>
      </span>
    );
  } else if (type === MESSAGE_MARKER.DISPLAYED) {
    check = (
      <span className="marker-container" style={{ color: 'green' }}>
        <i className="fa fa-check"></i>
        <i className="fa fa-check"></i>
      </span>
    );
  }
  return check;
};

const MessageItem = (props) => {
  const { message = {} } = props;

  const style = {
    textAlign: 'right',
    background: '#f4f0f0',
    padding: '11px 16px',
    border: 'transparent',
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    fontSize: 12,
    position: 'relative',
    direction: "rtl"
  };

  if (!message.incoming) {
    style.textAlign = 'left';
    style.background = '#f3f2fc';
    style.borderTopRightRadius = 10;
    style.borderTopLeftRadius = 0;
  }

  const renderBody = () => {
    // let body = `${dummyText}`;
    let linkArray = [];
    let body = `${message.body}`;
    console.log(body)
    const newOptions = body.split(optionSplitText);
    console.log(newOptions, "newoptions")
    const dotSplitArray = body.split(':-');
    let firstItem = dotSplitArray[0]
    let isOption = false;
    if (dotSplitArray.length > 1) {
      const firstIsNumber = Number(firstItem);
      isOption = !!firstIsNumber;
      console.log(isOption)

      for (let i = 0; i < newOptions.length; i += 2) {
        linkArray.push({ head: newOptions[i], url: newOptions[i + 1] });
      }
      console.log(linkArray)
    }
    var bodyD = isStringEmpty(body)
    console.log(body, "body")
    console.log(bodyD)
    const urls = getUrls(body) || [];

    if (urls && urls[0]) {
      console.log(urls)
      body = removeUrl(body);
      console.log(body, "bodyyyyy")
    }
    const options = body.split(optionSplitText);
    if (options[0] === ''){
      var bodyD = !isStringEmpty(body);
    }
    console.log(options, "------------------options")

    const text = options.map((m) => (
      <>
        {m.trim()}
        {m ? <br /> : ''}
      </>
    ));



    return (
      <>
        {!isOption && bodyD ? (
          <p className="message-item-body" style={style}>
            <span style={{ wordBreak: 'break-word', direction: 'rtl' }}>{text}</span>
          </p>
        ) : null}
        {!isOption && (urls.map((url) =>
          isPdfUrl(url) ?
            <PdfDownloader url={url} style={{ ...style, width: '92%', cursor: 'pointer' }} />
            : isYoutube(url) ?
            <ReactPlayer className="link-preview-container" url={url} width="80%" height="200px" /> 
            : <LinkDownloader url={url} style={{ ...style, width: '92%', cursor: 'pointer' }} />


        ))}
        {isOption && linkArray.map((item, index) => {
          if (item.head || item.url) {
            return <> <p className="message-item-body" style={index !== 0 ? { ...style, marginTop: "10px" } : style}>
              <span style={{ wordBreak: 'break-word', direction: 'rtl' }}>{item.head}</span>
            </p>
              {isPdfUrl(item.url) ?
                <PdfDownloader url={item.url} style={{ ...style, width: '92%', cursor: 'pointer' }} />
                : isYoutube(item.url) ?
                <ReactPlayer className="link-preview-container" url={item.url} width="80%" height="200px" /> 
                : <LinkDownloader url={item.url} style={{ ...style, width: '92%', cursor: 'pointer' }} />}
            </>
          }
        })
        }
      </>
    );
  };
  return (
    <>
      {message.incoming ? (
        <div className="message-item incoming d-flex align-items-center" style={{}}>
          <div class="outgoing_msg_icon">
            <img src={Chatbot} style={{ width: '100%', backgroundColor: '#F5F5F5', borderRadius: 50 }} />
          </div>
          <div style={{ flex: 1 }} className="incoming-msg-content">
            {renderBody()}
            <span className="message-item-time incoming-message-item-time d-flex p-0">
              {moment(message.timestamp).format('h:mm a')}
              <span className="p-0 m-0">{renderCheck(message.marker, message.notSent)}</span>
            </span>
          </div>
        </div>
      ) : (
        <div className="message-item d-flex align-items-center" style={{}}>
          <div class="outgoing_msg_icon">
            <img src={User} style={{ width: '100%' }} />
          </div>
          <div style={{ flex: 1 }} className="d-flex flex-column align-items-start">
            {renderBody()}
            <span className="message-item-time d-flex p-0">
              {moment(message.timestamp).format('h:mm a')}
              <span className="p-0 m-0">{renderCheck(message.marker, message.notSent)}</span>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageItem;
