import axios from 'axios';
import { RASA_URL, XMPP_SERVER_URL } from '../config';

axios.interceptors.response.use(
    function (response) {
      // console.log('interceptor response', response);
      return response;
    },
    function (error) {
      // console.log('interceptor response error', error);
      return Promise.reject(error);
    },
  );
  
  axios.interceptors.request.use(
    function (response) {
      // console.log('interceptor request', response);
      return response;
    },
    function (error) {
      // console.log('interceptor request error', error);
      return Promise.reject(error);
    },
  );

// export const getWebMessages = (data) => {
//   return axios.post(`https://mohe.omantel.om/rasaapp/api/dialog/getWebMessage`, data)
// }
export const getWebMessages = (data) => {
  console.log('data-------------------', data)
  return axios.post(`${RASA_URL}/dialog/getWebMessage`, data)
}

export const resumeBotChat = (sender) => {
  return axios.post(`${RASA_URL}/dialog/resume/chat`, {sender})
}
