import React, { useEffect, useState, useRef } from 'react';
import PdfLogo from '../../../assets/images/pdf-logo.png';
import { getFileNameFromUrl } from '../../../utils/filters';
import WebViewer from '@pdftron/pdfjs-express';

const PdfDownloader = (props) => {
  const [open,setOpen] = useState(false);
  const viewer = useRef(null);
  const openUrl = () => {
    // WebViewer(
    //   {
    //     path: '/webviewer/lib',
    //     initialDoc: props.url,
    //   },
    //   viewer.current)
    // ).then((instance) => {

    // });
    window.open(props.url)
  }


  return (
    <>
      <div onClick={openUrl} style={props.style} className="d-flex align-items-center justify-content-space-between mt-2 pdf-container">
        <div style={{ flex: 1 }} className="d-flex align-items-center">
          <img src={PdfLogo} className="pdf-logo" />
          <div className="pdf-name"></div></div>
        <span className="download-icon" ><i className="fa fa-download m-1"></i></span>
      </div>
      
      {/* <div style={{width:"92%"}} ref={viewer}></div> */}
    
    </>
  );
};

export default PdfDownloader;
