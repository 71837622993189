export const urlRegex = /(https?:\/\/[^\s]+)/g;
export const youTube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

export const getJid = (jid) => {
    return jid.split('/')[0]
}
  
export const getUsername = (jid) => {
    return jid.split('@')[0]
}
  
export const reverseString = (str) => {
    return (str === '') ? '' : reverseString(str.substr(1)) + str.charAt(0);
}
  
export const getUrls = (str) => {
  return str.match(urlRegex);
}
  
export const removeUrl = (str) => {
  return str.replace(urlRegex, '');
}
  
export const isPdfUrl = (url='') => {
  return url.indexOf('.pdf') != -1;
}
export const isYoutube = (url='') => {
  return url.match(youTube);
}
  
export const getFileNameFromUrl = (url='') => {
  return url.substring(url.lastIndexOf('/')+1);
}
  
export const isStringEmpty = (str='') => {
  console.log(str.replace("\n", ""))
  return !!str.replace("\n", "");
  
}
