import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getJid, getUsername } from 'utils/filters';
import * as XMPP from 'stanza';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { dataMessageBodyHint } from '.';
import { v4 as uuidv4 } from 'uuid';
import { resumeBotChat } from 'services/chatService';

export const CHATSTATES = {
  COMPOSING: "composing",
  PAUSED: "paused",
};

export const FETCH_LAST_ACTIVITY_TIMEOUT = 90000;

const ChatDataContext = React.createContext(null);

const ChatDataProvider = (props) => {
  const uniqueId = uuidv4();
  const tenDigit = uniqueId.slice(uniqueId.length - 10, uniqueId.length);

  const [clientData, setClientData] = useState();
  const [connected, setConnected] = useState(false);
  const [chat, setChat] = useState(false);
  const [emailPopup, setEmailPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [typing, setTyping] = useState(false);
  const [targetUser, setTargetUser] = useState();
  const [online, setOnline] = useState(true);
  const [getAgentStatus, setGetAgentStatus] = useState(false);
  const [agentChanged, setAgentChanged] = useState(false);
  const [activeOptions, setActiveOptions] = useState([]);
  const [messages, setMessages] = useState({});
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [senderId, setSenderId] = useState(tenDigit);
  const [sendMessageToBot, setSendMessageToBot] = useState();

  const currentUserRef = useRef({});
  const targetUserRef = useRef();
  const subscribedRef = useRef(false);

  useEffect(() => {
    const { jid, password } = window.sessionStorage;
    if (jid && password) {
      // connectWithCred(jid, password); 
    }
  }, []);


  const connectWithCred = (jid, password) => {
    if (connected) {
      clientData.disconnect();
    }
    const client = XMPP.createClient({
      password,
      jid,
      ...window.config.xmpp,
    });

    client.on("session:started", () => {
      setCurrentUser({ jid, password });
      client.getRoster();
      client.sendPresence();
      setEmailPopup(false);
      setChat(true);
      setConnected(true);
      storeCread(jid, password);
      currentUserRef.current = { jid, password };
    });

    client.on("disconnected", (d) => {
      setConnected(false);
      setClientData();
    });
    client.on("auth:failed", (d) => {
      toast.error("Connection Failed, Please Try Again.");
      storeCread("", "");
    });
    client.on("subscribe", (d) => onSubscribe(d, client));
    client.on("chat:state", (d) => {
      if (d.chatState === CHATSTATES.COMPOSING) {
        setTyping(true);
      } else {
        setTyping(false);
      }
    });

    client.on("unsubscribe", (d) => {
    });

    client.on("presence", (d) => {
      if (d.error) return;
      if (getJid(d.from) !== jid) {
        onSubscribe(d, client);
      }
    });

    client.connect();
    setClientData(client);
  };

  // Add message on list when chat with bot
  const addMessage = (body, incoming) => {
    console.log(body,"body",incoming,"incoming")
    const id = uuidv4();
    const allMessages = messages;
    allMessages[id] = {body: body, incoming, id};
    setMessages(allMessages);
    
    setScrollToBottom(Math.random());
  }

  const onSubscribe = (d, client) => {
    const targetJid = getJid(d.from);
    // console.log("ChatDataContext Stanza onSubscribe", {
    //   d,
    //   targetJid,
    //   currentTargetUser: targetUserRef.current,
    //   subscribedRef: subscribedRef.current,
    // });
    if (!targetUserRef.current) {
      subscribe(targetJid, client);
    }
    // console.log("ChatDataContext Stanza subscribe", d);
  };

  const subscribe = (jid, client) => {
    // console.log("ChatDataContext Stanza subscribe function called", jid);
    subscribedRef.current = true;
    client.acceptSubscription(jid);
    setTargetUser(jid);
    targetUserRef.current = jid;
    setOnline(true);
    addMessage(` تم تحويل الدردشة  إلى الموظف المختص: ${getUsername(jid)}`, true);
  };

    // When agent send a data message to user to initiate chat, but user already has chat with other user then user denied the chat and send and data message back again to unsubscribe her.
    const unsubscribeMe = (to) => {
      const data = {
        from: currentUser.jid,
        type: "unsubscribe",
      };
      const msgData = {
        to,
        body: `${dataMessageBodyHint}${JSON.stringify(data)}`,
      };
      clientData.sendMessage(msgData);
    };


  let interval = null;

  useEffect(() => {
    interval = setInterval(
      () => setGetAgentStatus(Math.random),
      FETCH_LAST_ACTIVITY_TIMEOUT
    );
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (getAgentStatus) {
      getLastActivity();
    }
  }, [getAgentStatus]);

  const getLastActivity = () => {
    if (!clientData || !connected) {
      clearInterval(interval);
      return;
    }
    if(!targetUser) return;
    clientData
      .getLastActivity(targetUser)
      .then((res) => {
        // console.log("ChatDataContext getLastActivity res", { res });
        setOnline(!res.seconds);
      })
      .catch((error) => {
        // console.log("ChatDataContext getLastActivity error", error);
      });
  };


  const handleDataMessage = (body) => {
    // console.log("ChatDataContext handleDataMessage data", { body });
    const splitArr = body.split(dataMessageBodyHint);
    if (splitArr[1]) {
      const data = JSON.parse(splitArr[1]) || {};
      if (currentUserRef.current === data.from) return;
      if (data.type === "init" && !targetUserRef.current) {
        subscribe(data.from, clientData);
      } else if (data.type === "transfer") {
        if (targetUserRef.current && targetUserRef.current !== data.from) {
          clientData.unsubscribe(targetUserRef.current);
          setAgentChanged(`Chat Transfered to ${getUsername(data.from)}`);
          setTimeout(() => {
            setAgentChanged(false);
          }, 10000);
        }
        subscribe(data.from, clientData);
      } else if (data.type === "unsubscribe") {
        // console.log("ChatDataContext handleDataMessage unsubscribe", {
        //   body,
        //   data,
        //   currentUserRef,
        // });
        unsubscribeMe(data.from);
      } else if(data.type === 'end'){
        endAgentChat();
      }
    }
  };


  useEffect(() => {
    currentUserRef.current = currentUser;
  }, [currentUser]);

  const handleClose = () => {
    setChat(false);
    window.top.postMessage(
      JSON.stringify({
        chatbotClose: true,
      }),
      "*"
    );
  };

  const resumeBot = () => {
    resumeBotChat(senderId).then(res => {
      addMessage(`أنت الآن متصل ببرنامج الدردشة الآلي`, true);
      setSendMessageToBot('hi');
    })
  }

  const endAgentChat = () => {
    console.log("ChatDataContext agent ended the chat");
    addMessage(`أنهى الوكيل المحادثة`, true);
    resumeBot();
    logout();
  }

  const logout = () => {
    clientData && clientData.disconnect();
    storeCread("", "");
    setTargetUser();
    clearInterval(interval);
    setTyping(false);
    setOnline(true);
  };

  const storeCread = (jid, password) => {
    sessionStorage.setItem("jid", jid);
    sessionStorage.setItem("password", password);
  };

  // console.log('ChatDataContextProvider Render', {messages})

  return (
    <ChatDataContext.Provider
      value={{
        client: clientData,
        connect: connectWithCred,
        handleClose,
        handleDataMessage,
        agentChanged,
        online,
        typing,
        connected,
        targetUser,
        currentUser,
        logout,
        addMessage,
        setActiveOptions,
        activeOptions,
        setScrollToBottom,
        scrollToBottom,
        botMessages: messages,
        setBotMessages: setMessages,
        senderId,
        sendMessageToBot
      }}
      {...props}
    />
  );
};

const useChatData = () => React.useContext(ChatDataContext);

export default ChatDataContext;
export { ChatDataProvider, useChatData };