import React, { useEffect, useRef } from "react";
import { Button } from "reactstrap";
import MessageItem from "./messageItem";
import { MessageSquare } from "react-feather";
import { useChatData } from "../chatDataContext";
import Chatbot1 from "../../../assets/images/chatbot1.png";
import Chatbot from "../../../assets/images/chatbot.svg";
import LogoMohe from "../../../assets/images/logoMohe.png";

const MessagesList = (props) => {
  const { activeOptions = [] } = props;
  const { scrollToBottom, botMessages = {} } = useChatData();
  const messagesObject = { ...props.chatMessages, ...botMessages };
  const messages = Object.values(messagesObject);
  const messagesLength = messages.length;
  const listEndRef = useRef();

  useEffect(() => {
    if (scrollToBottom || messagesLength.length > 5) {
      scrollToBottomList();
    }
  }, [scrollToBottom, messagesLength]);

  const scrollToBottomList = () => {
    if (listEndRef.current) {
      listEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderButton = (item) => (
    <Button
      outline
      size="sm"
      className="msg_option_style btn btn-secondary"
      onClick={() => props.onSelectOption(item.payload, item.title)}
    >
      {item.title}
    </Button>
  );

  const renderOptions = () => {
    const extraOptions = [<br />];
    return (
      <div
        className="mx-3"
        style={{ padding: "2% 2% 10%", direction: "rtl", textAlign: "right" }}
      >
        {activeOptions.map((o) => {
          console.log(o)
          // if (
          //   o.value === "exit" ||
          //   o.value === "0" ||
          //   o.body === "القائمة الرئيسية"
          // ) {
          //   extraOptions.push(renderButton(o));
          //   return null;
          // }
          return renderButton(o);
        })}
        {extraOptions}
        {/* <img src={LogoMohe} style={{ width: 35, margin: "0 10px" }} /> */}
      </div>
    );
  };

  return (
    <div className="outgoing" style={{}}>
      <div className="row">
        <div className="msg_option_center">
          {/* <MessageSquare color="red" /> */}
          <img src={Chatbot} style={{ width: 60 }} />
          <p>
            مرحبا بكم في النسخة التجريبية للمساعد الرقمي بمركز القبول الموحد
          </p>
          <p>
            أنا المساعد الرقمي للمركز، يسعدني مساعدتكم للرد على بعض إستفساراتكم<br/>
          هذه النسخة تجريبية وقد يكون هناك نقص في بعض المعلومات وبالتالي يعتبر
            دليل الطالب واعلان المستجدات والتنبيهات المعلن عنها والموقع الرسمي
            للمركز هي المرجع النهائي  {" "}
          </p>
          <p>
            ولمزيد من المعلومات يرجى الرجوع إلى صفحة الطالب بالموقع الالكتروني
            لمركز القبول الموحد.<br/> عند إستخدام الأرقام في عملية البحث، يرجى
            إستخدام الصيغة التالية: (.....321) 
          </p>
        </div>
      </div>
      <div className="row">
        <div className="outgoing_msg" style={{ width: "100%" }}>
          {messages.map((message) => (
            <MessageItem key={message.id} {...props} message={message} />
          ))}
          {renderOptions()}
          <div
            style={{ float: "left", clear: "both" }}
            ref={(el) => {
              listEndRef.current = el;
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MessagesList;
