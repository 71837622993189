import React from 'react';
import Linkd from '../../../assets/images/link.png';
import { getFileNameFromUrl } from '../../../utils/filters';

const LinkDownloader = (props) => {
  const openUrl = () => {
    window.open(props.url)
  }


  return (
    <>
      <div onClick={openUrl} style={props.style} className="d-flex align-items-center justify-content-space-between mt-2 pdf-container">
        <div style={{ flex: 1 }} className="d-flex align-items-center">
        <span className="download-icon" ><i className="fa fa-external-link m-1"></i></span>
          <div style= {{wordBreak: 'break-word',textAlign: 'left', padding: '0px 10px',marginLeft: '10px', color:'blue'}}>{props.url}</div></div>
        
      </div>
    </>
  );
};

export default LinkDownloader;
