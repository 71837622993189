import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getJid } from 'utils/filters';
import { v4 as uuidv4 } from 'uuid';
import { isDataBody } from '..';
import _ from 'lodash';
import ChatBot from './chatBot';
import { CHATSTATES, useChatData } from '../chatDataContext';

export const MESSAGE_MARKER = {
  MARKABLE: 'markable',
  RECEIVED: 'received',
  DISPLAYED: 'displayed',
};


const USER_MESSAGES_LOCALSTORAGE = 'user_chat_messages_'

const Messages = (props) => {
  const [messageText, setMessageText] = useState('');
  const [update, setUpdate] = useState(false);

  const { targetUser, botMessages={}, setBotMessages } = useChatData();
  const { client = {}, connected, currentUser = {} } = props;

  const typingTimerRef = useRef();
  const messagesRef = useRef({});
  const messages = Object.values(messagesRef.current);

  const localStorageKey = `${USER_MESSAGES_LOCALSTORAGE}${targetUser}`;

  useEffect(() => {
    if (!update) return;
    localStorage.setItem(localStorageKey, JSON.stringify(messagesRef.current))
  }, [update]);

  useEffect(() => { // When connect/disconnet with agent then need to show bot and agent messages togather so need to merge both
    const messages = messagesRef.current;
    setTimeout(() => { // Because of some callback functions refill the bot messages
      messagesRef.current = {...messages, ...botMessages};
      setBotMessages({});
    }, 2000);
    setUpdate(Math.random());
  }, [targetUser]);

  useEffect(() => {
    // const allPreviousMessages = localStorage.getItem(localStorageKey);
    // messagesRef.current = JSON.parse(allPreviousMessages || "{}") || {};
    // console.log('ChatDataContext allPreviousMessages', allPreviousMessages, JSON.parse(allPreviousMessages || "{}"))
    // setUpdate(null);
  }, [])

  useEffect(() => {
    if (connected) {
      client.on('message', onMessage);
      client.on('message:sent', onSentMessage);
      client.on("marker:received", onReceipt);
      client.on("marker:displayed", onReceipt);
    }
  }, [connected]);

  const onReceipt = (d) => {
    console.log('ChatDataContext Messages marker', {messages, d, currentUser})
  if(getJid(d.from) !== currentUser.jid){
    const marker = d.marker || {};
    const messages = messagesRef.current;
    const message = messages[marker.id];
    messages[marker.id] = {...message, marker};
    setUpdate(Math.random());
}};

  const onMessage = (msg = {}, notSent) => {
    console.log('ChatDataContext messages onMessage', { msg, currentUser });
    const jid = getJid(msg.from);
    const currentJid = getJid(currentUser.jid);
    if (!msg.body) return;
    if(isDataBody(msg.body)) {
      props.handleDataMessage(msg.body);
      return;
    }

    const messages = messagesRef.current;
    messages[msg.id] = { ...msg, timestamp: new Date(), incoming: currentJid !== jid, notSent };
    client.markDisplayed(msg);
    setUpdate(Math.random());
  };

  const onSentMessage = (msg={}) => {
    onMessage({...msg, from: currentUser?.jid})
  }

  const sendMessage = (e) => {
    e.preventDefault();
    if (!messageText.trim()) return;
    const message = {
        to: targetUser,
        body: messageText,
        marker: {type: MESSAGE_MARKER.MARKABLE},
        id: uuidv4(),
    }
    client.sendMessage(message);
    onMessage({...message, from: currentUser.jid}, true)
    setMessageText('');
    clearTimeout(typingTimerRef.current);
    sendChatStateDebounce(CHATSTATES.PAUSED, targetUser, client);
  };

  const handleMessage = (e) => {
    const value = e.target.value;
    setMessageText(value);
    clearTimeout(typingTimerRef.current);
    console.log('ChatDataContext Messages handleMessage', {client, value});
    if(value.length === 1){
      sendChatState(CHATSTATES.COMPOSING, targetUser, client);
    } else {
      sendChatStateDebounce(CHATSTATES.COMPOSING, targetUser, client);
    }
    setTypingTimeout();
  };

  const setTypingTimeout = () => {
    typingTimerRef.current = setTimeout(() => {
      sendChatStateDebounce(CHATSTATES.PAUSED, targetUser, client);
    }, 5000);
  };

  const sendChatStateDebounce = useCallback(
    _.debounce((state, to, client) => sendChatState(state, to, client), 500),
    [],
  );

  const sendChatState = (state, to, clientData) => {
    console.log('ChatDataContext Messages sendChatState', {state, to, clientData});
    clientData.sendMessage({
      chatState: state,
      to,
      type: 'chat',
    });
  };

  return <ChatBot chatMessages={messagesRef.current} sendMessage={sendMessage} messageText={messageText} handleMessage={handleMessage}/>
};

export default Messages;
