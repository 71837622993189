// export const SERVER_URL = "http://2.56.215.239:3010/api";

// export const SERVER_URL = "https://mohe.omannews.tk/moheapp/api";
// export const SERVER_URL = "http://2.56.215.239:3010/api";


// Production
export const RASA_URL = "https://chatbot.heac.gov.om/rasaapp/api";

// Dev
// export const RASA_URL = "http://93.115.22.132:3033/api";
// export const RASA_URL = "https://rasaapp.moheoman.live/api";npm start