import React from "react";
import { Minus } from "react-feather";
import Messages from "./messages";
import { ChatDataProvider, useChatData } from "./chatDataContext";
import { getUsername } from 'utils/filters';

export const dataMessageBodyHint = "__!data__";

export const isDataBody = (body) => {
  const splitArr = body.split(dataMessageBodyHint);
  return !!splitArr[1];
};

const CustomerChatBoard = () => {
  const { client, handleClose, handleDataMessage, agentChanged, online, typing, connected, targetUser, currentUser } = useChatData();

  return (
    <div className="chatComponent">
      <div className="activeCustomerName">
        <div className="" style={{
          display: "flex", 
          justifyContent: 'space-between'
          }}>
          <div className="col-md-10 col-sm-10 col-xs-9 col-8 d-flex px-0 justify-content-center align-self-center ml-5 align-items-center" style={{ overflow: "hidden" }}>
            {targetUser ? <span
              style={{ backgroundColor: online ? "green" : "#9a0000e3" }}
              className="user-chat-status mr-1"
            /> : null}
            <span className="targetUserName">
              <span>{targetUser ? getUsername(targetUser) : "HEAC"}</span>
              {typing ? <span style={{}}><span className="typing-text ml-1">typing...</span></span> : null}
            </span>
          </div>
          <div
            className="closeIcon col-md-1 col-sm-1 col-xs-1 col-1"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          >
            <Minus style={{ marginTop: 6, color: 'black'}}/>
          </div>
        </div>
      </div>
      <Messages
        client={client}
        connected={connected}
        currentUser={currentUser}
        agentChanged={agentChanged}
        handleDataMessage={handleDataMessage}
      />
    </div>
  );
};

export default (props) => {
  return (
    <ChatDataProvider>
      <CustomerChatBoard {...props} />
    </ChatDataProvider>
  );
};
