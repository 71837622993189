import React from 'react';
import { ChevronsLeft } from 'react-feather';
import _ from 'lodash';
import Send from "../../../assets/images/send.svg";

const InputBox = (props) => {
  return (
    <form className="d-flex chat-input-box" onSubmit={props.onSubmit}>
        <div className="input-container">
        <div className="">
            <button type="submit" class="chat-send-button"><img class="SubmitButtonstyled__SubmitButtonIcon-sc-1jb5acj-1 jMziUS" src={Send}/></button>
        </div>
            <input
            type="text"
            className="form-control form-control-style input-box"
            placeholder="أدخل رسالة"
            value={props.value}
            onChange={props.onChange}
            style={{direction: "rtl"}}
            />
        </div>
        
    </form>
  );
};

export default InputBox;
