import React, { useEffect, useState } from "react";
import MessagesList from "./messagesList";
import _ from "lodash";
import { getWebMessages } from "services/chatService";
import { useChatData } from "../chatDataContext";
import InputBox from "./inputBox";
import axios from "axios";
export const optionSplitText = "\n";

const isAgentConfirmText = ["هل ترغب في التحدث مع وكيل بشري؟"];
const exitTexts = ["للخروج من"];
const excludeExitTexts = [
  "مرحبًا ساتان انقر نعم لمعرفة البرنامج المعروض أو انقر لا للخروج من القائمة",
];
const mainMenuTexts = ["إلى القائمة الرئيسية"];
const mainMenuTextsWithZeroValue = ['"0" للعودة إلى القائمة الرئيسية'];
const previousTexts = ["الخيار السابق", "إلى الخيار الثمين", "للرجوع أو اكتب"];
const defaultOptions = [
  { body: "خروج", value: "exit" },
  { body: "القائمة السابقة", value: "0" },
  { body: "القائمة الرئيسية", value: "1" },
];

const defaultButton = [
  { title: "القائمة الرئيسية", payload: "/main_menu" },
  { title: "خروج", payload: "/restart" },
];

// const getIsExitText = (body, array) => {
//   let isExitText = false;
//   array.map((t, index) => {
//     if (isExitText) return;
//     isExitText = body.indexOf(t) !== -1;
//   });

//   return isExitText;
// }
const getIsExitText = (body, array, exclude = []) => {
  let isExitText = false;
  array.map((t, index) => {
    if (isExitText) return;
    isExitText = body.indexOf(t) !== -1 && !exclude.includes(body);
  });

  return isExitText;
};

const ChatBot = (props) => {
  const [messageText, setMessageText] = useState(
    "السلام عليكم ورحمة الله وبركاته"
  );
  const [activeOptions, setActiveOptions] = useState([]);
  const [ip, setIP] = useState("");
  const {
    connect,
    targetUser,
    addMessage,
    setScrollToBottom,
    senderId,
    sendMessageToBot,
  } = useChatData();

  useEffect(() => {
    if (!sendMessageToBot) return;
    sendWebMessage(sendMessageToBot, sendMessageToBot);
  }, [sendMessageToBot]);

  useEffect(() => {
    getData();
    sendWebMessage();
  }, []);

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data.IPv4, "rerertetet");
    setIP(res.data.IPv4);
  };

  const handleAgentChatConfirm = () => {
    let options = [
      {
        body: "نعم",
        value: "1",
      },
    ];
    options.push(defaultOptions[0]);
    addMessage(isAgentConfirmText[0], true);
    setActiveOptions(options);
    setScrollToBottom(Math.random());
  };

  const sendWebMessage = (val, body) => {
    console.log(body, "bodydydydydyd");
    const data = {
      auth: "PdfgdfJFDGTfrfgdsf",
      sender: senderId,
      queryValue: (body || messageText).trim(),
      message: (val || messageText).trim(),
      ip: ip,
    };
    console.log(senderId, "senderrrrrrrrr");
    console.log(ip, "ipipipipipiipi");
    addMessage(body || messageText, false);
    setMessageText("");
    setActiveOptions([]);

    getWebMessages(data)
      .then((res) => {
        const { data = {} } = res;
        console.log(res, "rersrsrs----------------");
        const message = `${data.message || ""}`;
        if (data.buttons.length) {
          setActiveOptions(data.buttons);
        }
        if (data.buttons.length === 0 && val !== "/restart") {
          setActiveOptions(defaultButton);
        }
        if (data.jidUsername && data.password) {
          // Chat transfered to agent, so need to connect xmpp
          // connect('user4706@omannews.tk', 'user4706');
          connect(data.jidUsername, data.password);
          addMessage(
            "سوف تحول  الدردشة إلى الموظف المختص، يرجى الإنتظار",
            true
          );
          return;
        }
        const isAgentConfirm = getIsExitText(message, isAgentConfirmText);
        if (isAgentConfirm) {
          handleAgentChatConfirm();
          return;
        }
        const splitArray = message.split(optionSplitText);
        console.log(message, "message");
        console.log(splitArray, "splitArray");
        let options = [];
        let hasMainMenu = false;
        let hasMainMenuWithZeroValue = false;

        let hasPrevious = false;
        let hasExit = false;

        let messageString = "";
        splitArray.map((body) => {
          let isOption = false;
          let isMainMenu = getIsExitText(body, mainMenuTexts);
          let isMainMenuWithZeroValue = getIsExitText(
            body,
            mainMenuTextsWithZeroValue
          );
          let isPrevious = getIsExitText(body, previousTexts);
          console.log(body, "body");
          const value = body.replace(/[^0-9]/g, "");
          console.log(value, "value");
          const dotSplitArray = body.split(".");
          console.log(dotSplitArray, "dotSplitArray");
          let firstItem = dotSplitArray[0].replace(":", ""); // Specific case
          console.log(firstItem, "firstItem");
          if (dotSplitArray.length > 1) {
            const firstIsNumber = Number(firstItem);
            isOption = !!firstIsNumber;
            console.log(isOption);
          }

          // hasExit = getIsExitText(body, exitTexts);
          hasExit = getIsExitText(body, exitTexts, excludeExitTexts);
          console.log(hasExit, "hasExit");
          if (isOption) {
            options.push({ value: dotSplitArray[0], body: dotSplitArray[1] });
            console.log(options, "otpions");
          } else if (value != "0" && !hasExit && !isPrevious && !isMainMenu) {
            messageString += `${body}\n`;
            console.log(messageString);
          }
          // if (hasExit) {
          //   messageString += `${body}\n`;
          //     console.log(messageString)
          // }
          if (!hasMainMenu) {
            hasMainMenu = isMainMenu;
          }
          if (!hasPrevious) {
            hasPrevious = isPrevious;
          }
          if (!hasMainMenuWithZeroValue) {
            hasMainMenuWithZeroValue = isMainMenuWithZeroValue;
          }
        });
        addMessage(messageString, true);
        if (hasPrevious) {
          options.push(defaultOptions[1]);
        }

        if (val !== defaultOptions[0].value || hasExit) {
          // when not exit
          options.push(defaultOptions[0]);
          hasExit = true;
        }
        if (hasMainMenu) {
          const option = {
            ...defaultOptions[2],
            value: hasMainMenuWithZeroValue ? "0" : "1",
          };
          options.push(option);
        }
        // if (options.length) {
        //   setActiveOptions(options);
        // }

        setScrollToBottom(Math.random());
      })
      .catch((error) => {
        connect("jid@gmail.com", "password");
      });
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (!messageText.trim()) return;

    sendWebMessage();
  };

  const handleMessage = (e) => {
    const value = e.target.value;
    setMessageText(value);
  };

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ height: "100%" }}
    >
      <div className="activeChatContent">
        <MessagesList
          activeOptions={activeOptions}
          onSelectOption={sendWebMessage}
          {...props}
        />
      </div>
      <div className="OnlineCustomerFooter">
        <div className="d-flex flex-wrap">
          <InputBox
            onSubmit={targetUser ? props.sendMessage : sendMessage}
            onChange={targetUser ? props.handleMessage : handleMessage}
            value={targetUser ? props.messageText : messageText}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
